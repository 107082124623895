/* COLOR CONSTANTS - DO NOT HARD CODE COLORS ANYWHERE BUT HERE FOR THE SCSS FILE */

$LSTV_YELLOW: #fff072;
$TEXT_AND_SVG_BLACK: #323233;
$PRIMARY_COLOR: #b86747;
$PRIMARY_HIGHLIGHT: #e3a887;
$SECONDARY_COLOR: #e3a887;

$MID_GRAY_COLOR: #ececec;
$DARK_GRAY_COLOR: #c9c4c4;
$LIGHT_GRAY_COLOR: #c9c4c4;
